import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false

/* Vue.mixin({
  data: function () {
    return {
      auth_token: 'nobuono'
    }
  }
}) */

new Vue({
  data: { auth_token: 'no-token' },
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
