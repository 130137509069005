<template>
  <v-container id="login-container">
    <v-card class="pa-3">
      <v-card-title>Please login</v-card-title>
      <v-divider />
      <p>
         Userid: <input v-model="username"
         placeholder="name.surname"></p>
      <p>
         Password: <input v-model="password"
         type="password"
         placeholder="password"></p>
      <v-btn x-large
      color="primary"
      class="my-5"
      @click="authenticate(username, password)">Login</v-btn>

    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'Login',
  data: () => ({
    username: null,
    password: null
  }),
  methods: {
    authenticate (username, password) {
      fetch('https://greenpass.nomoscsp.eu:8000/token-auth/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({ data })
        body: JSON.stringify({ username, password })
      }).then(response => {
        response.json().then(data => {
          this.$root.$data.auth_token = data.token
          localStorage.setItem('username', this.username)
          this.$router.push({
            name: 'Home',
            params: { username: this.username }
          })
        })
      })
    }
  }
}
</script>
